import React, { useState, useEffect } from 'react';
import { IconCancel, IconRemove } from 'svgIcons/MotionPortalIcons';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';

import './_style.scss';

const GeneralInputComponent = ({
	validationFunction,
	value,
	isRequired,
	label,
	className,
	numeric,
	isOutOfRange,
	onChange,
	onBlur,
	placeholder,
	disabled,
	description,
	requireMessage,
	validationMessage,
	clearable,
	onClear,
	id,
}) => {
	const [isValidationError, setErrorStatus] = useState(false);
	const [isRequiredError, setRequiredStatus] = useState(false);

	useEffect(() => {
		if (validationFunction && value && value !== '') {
			setErrorStatus(!validationFunction(value));
		}
	}, [value]);

	const validateInput = (inputValue) => {
		if (validationFunction && inputValue && inputValue !== '') {
			if (!validationFunction(inputValue)) {
				return true;
			}
		}
		return false;
	};

	const requiredValidation = (inputValue) => {
		if (isRequired) {
			return inputValue && inputValue !== '' ? false : true;
		}
		return false;
	};

	return <div className={`general-input-container ${className ? className : ''}`} id={id} role='input'>
		<div className='input'>
			{label && label.length > 0 && <div className='general-input-label'>
				{label}
			</div>}
			<div className='input-container'>
				<input
					id={id}
					onChange={(e) => {
						if (numeric) {
							if (/^-?\d*[.,]?\d*$/.test(e.target.value) || e.target.value.length === 0) {
								if (isOutOfRange && isOutOfRange(e.target.value)) {
									return;
								}
								if (validationFunction) {
									setErrorStatus(!validationFunction(e.target.value));
								}
								onChange(e.target.value);
							}
						} else {
							onChange(e.target.value);
						}
					}}
					onBlur={(e) => {
						onBlur && onBlur();
						setRequiredStatus(requiredValidation(e.target.value));
						setErrorStatus(validateInput(e.target.value));
					}}
					type='text'
					className={`form-control input-search-condition-monitoring ${isValidationError || isRequiredError ? 'invalid-value' : ''}`}
					placeholder={placeholder}
					value={value === null ? '' : value}
					disabled={disabled}
				/>
				{description ?
					<div className='description-container'>
						{description}
					</div> : null}
				{isValidationError || isRequiredError ?
					<div className='error-container' >
						<IconCancel
							showCircle={true}
							color={colors.white}
							fillColor={colors.pomegranate}
							viewBox='0 0 50 50'
							width={18}
							height={18}
						/>{isRequiredError ? requireMessage :
							isValidationError ? validationMessage : null}
					</div> : null}

			</div>
		</div>
		{clearable && <div className='clear-input-button' onClick={() => { onClear(); }}>
			<IconRemove width='16' height='16' color={colors.dustyGray} />
		</div>}
	</div>;
};

GeneralInputComponent.propTypes = {
	onChange: PropTypes.func,
	clearable: PropTypes.bool,
	onClear: PropTypes.func,
	placeholder: PropTypes.string,
	value: PropTypes.any,
	label: PropTypes.string,
	validationFunction: PropTypes.func,
	isRequired: PropTypes.bool,
	requireMessage: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	numeric: PropTypes.bool,
	onBlur: PropTypes.any,
	id: PropTypes.string,
};

export default GeneralInputComponent;
