import React from 'react';
import PropTypes from 'prop-types';

import IconBatteryFull from 'svgIcons/MotionPortalIcons/IconBatteryFull';
import IconBatteryHalf from 'svgIcons/MotionPortalIcons/IconBatteryHalf';
import IconBatteryEmpty from 'svgIcons/MotionPortalIcons/IconBatteryEmpty';

import './_style.scss';

const Battery = ({ value }) => {
    const getBatteryStatus = (value) => {
        if (value > 50) {
            return <IconBatteryFull value={value + ' %'}/>;
        }
        if (value >= 15) {
            return <IconBatteryHalf value={value + ' %'}/>;
        }
        return <IconBatteryEmpty value={value + ' %'}/>;
    };

    return <div className='battery-component'>
            {getBatteryStatus(value)}
        </div>;
};

Battery.propTypes = {
    value: PropTypes.number
};

export default Battery;
