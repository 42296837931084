import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { IconUnchecked, IconChecked } from 'svgIcons/MotionPortalIcons';

import colors from 'theme/_colors.scss';
import './_style.scss';


const SwitchComponent = ({ onChange, checked = false, disabled, className, disableHandleIcons = false }) => {
    return <Switch
        onChange={onChange}
        checked={!!checked}
        disabled={disabled}
        height={24}
        width={48}
        offColor={colors.silver}
        onColor={colors.dodgerBlue}
        handleDiameter={18}
        uncheckedIcon={true}
        checkedIcon={true}
        checkedHandleIcon={!disableHandleIcons ? IconChecked('35px', '20px') : null}
        uncheckedHandleIcon={!disableHandleIcons ? IconUnchecked('35px', '20px') : null}
        className={`${className} general-switch ${disabled ? 'disabled' : ''} ${checked ? 'checked' : 'empty'} ${disableHandleIcons ? 'toggle' : ''}`}
        role='switch'
    />;
};

SwitchComponent.propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    disableHandleIcons: PropTypes.bool
};

export default SwitchComponent;
