/* eslint-disable import/no-unused-modules */
/**
 *
 * ContextMenuComponent implementation
 *
 * Usage:
 *
 * Provide the context menu options in this format:
 * [{
 *    id: 'option_id_1',
 *    title: 'Option #1',
 *    disabled: false,
 * 	  ?onOptionSelect: function(),
 *  }, {
 *    id: 'option_id_2',
 *    title: 'Option #2',
 *    disabled: false,
 *    ?onOptionSelect: function(),
 *  }, {
 *    id: 'option_id_3',
 *    title: 'Option #3',
 *    disabled: true,
 *     ?onOptionSelect: function(),
 *  }]
 *
 * The onSelect function is called when an enabled context menu option is clicked. The id of the
 * option is provided as a parameter.
 * Alternatively an onOptionSelect function can be provided for each option, which would be called when the option is selected.
 *
 * The component takes care of its visibility (menu open / closed) independently.
 *
 */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import listenForOutsideClick from './helper';
import { IconKebab } from 'svgIcons/MotionPortalIcons';
import './style.scss';

const ContextMenuComponent = (props) => {
	const menuRef = useRef(null);
	const [listening, setListening] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	useEffect(listenForOutsideClick(listening, setListening, menuRef, setIsOpen));

	/**
	 * Handles the item selection event.
	 */
	const handleSelection = (option) => {
		if (isOpen) {
			toggle();
		}
		const callbackFunction = option.onOptionSelect || props.onSelect;

		if (typeof callbackFunction === 'function') {
			callbackFunction(option.id);
		}
	};

	const buttonClass = isOpen ? 'context-menu-button-active' : 'context-menu-button';
	return (
		<div className='context-menu' ref={menuRef} role='context-menu'>
			<div
				id={props.id}
				className={buttonClass}
				onClick={toggle}
			>
				<IconKebab active={isOpen} />
			</div>
			{isOpen &&
				<div className='context-menu-list'>
					{props.options.map(option => {
						const cssClass = option.disabled === true ? 'context-menu-list-item-disabled' : 'context-menu-list-item';
						return (
							<div
								id={`contextMenuItem_${option.id}`}
								key={`contextMenuItem_${option.id}`}
								className={cssClass}
								role='presentation'
								onClick={() => {
									if (!(option.disabled === true)) {
										handleSelection(option);
									}
								}}
							>
								{option.title}
							</div>
						);
					}
					)}
				</div>
			}
		</div>
	);
};

ContextMenuComponent.defaultProps = {
	id: '',
	options: [],
	onSelect: undefined,
};

ContextMenuComponent.propTypes = {
	id: PropTypes.string,
	options: PropTypes.array,
	onSelect: PropTypes.func,
};

export default ContextMenuComponent;
