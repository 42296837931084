import React from 'react';
import PropTypes from 'prop-types';

import './_style.scss';


const getClassName = (disabled, customClassName, type) => {
	let className = customClassName ? `${customClassName} general-button` : 'general-button';
	if (disabled) {
		className = `${className} disabled`;
	}
	if (type) {
		className = `${className} ${type}`;
	}
	return className;
};

const GeneralButton = (props) => <div
	className={getClassName(props.disabled, props.className, props.type)}
	id={props.id}
	onClick={props.onClick}
>
	{props.icon ? props.icon : null}
	{props.text && <div className='button-text' role='button'>{props.text}</div>}
</div>;

GeneralButton.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	disabled: PropTypes.any,
	icon: PropTypes.shape({}),
	onClick: PropTypes.func,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	type: PropTypes.any
};

export default GeneralButton;
