import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import './_style.scss';

const getClassName = (disabled, customClassName, type) => {
    let className = customClassName ? `${customClassName} general-button dropdown-toggle` : 'general-button dropdown-toggle';
    if (disabled) {
        className = `${className} disabled`;
    }
    if (type) {
        className = `${className} ${type}`;
    }
    return className;
};

// eslint-disable-next-line react/display-name
const ExtraActionsToggle = React.forwardRef(({ children, onClick, disabled }, ref) =>
    <a
        href=''
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            if (!disabled) {
                onClick(e);
            }
        }}
    >
        {children}
    </a>
);


const GeneralButtonDropDown = (props) => {
    const renderOptionMenu = () => {
        return props?.options?.map((item) => {
            return (
                <Dropdown.Item key={item.value} value={item} onClick={() => props.onSelect(item.value)}>
                    {item.label}
                </Dropdown.Item>
            );
        });
    };

    return (
        <Dropdown drop='down-centered'>
            <Dropdown.Toggle as={ExtraActionsToggle} disabled={props.disabled}>
                <div
                    className={getClassName(props.disabled, props.className, props.type)}
                    id={props.id}
                >
                    {props.icon ? props.icon : null}
                    <div className='button-text' role='button'>{props.text}</div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {renderOptionMenu()}
            </Dropdown.Menu>

        </Dropdown>
    );


};


GeneralButtonDropDown.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.any,
    icon: PropTypes.shape({}),
    onSelect: PropTypes.func,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.any,
    options: PropTypes.arrayOf(PropTypes.object)
};

export default GeneralButtonDropDown;

