import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { IconClose } from 'svgIcons/MotionPortalIcons';

const multiChartOptions = {
    SINGLE: 'single',
    MULTI: 'multi'
};

const RemovableLegend = (props) => {
    const { t: translate } = useTranslation();

    const { legend, signalSourceName, multiChart } = props;
    const valueSuffix = legend.tooltipOptions?.valueSuffix?.trim();

    const renderCloseButton = multiChart === multiChartOptions.MULTI ? null : <span id={legend.userOptions.id} className='remove-button'>
                <IconClose />
            </span>;

    return (
        <span className='legend-item-wrapper'>
            <span className='signal-source-name' title={signalSourceName}>{signalSourceName}</span>
            <span className='item-kpi-name' title={`${legend.name} ${!legend.userOptions.allSignal && valueSuffix ? `(${valueSuffix})` : ''} ${!legend.xData || !legend.xData.length ? `(${translate('ABB.Powertrain.Frontend.noDataLabel')})` : ''}`}>
                {signalSourceName && <span className='kpi-name-separator'> - </span>}
                <span>{legend.name}</span>
                {!legend.userOptions.allSignal && valueSuffix ? <span>{` (${valueSuffix})`}</span> : null}
                {!legend.xData || !legend.xData.length ? <span>{` (${translate('ABB.Powertrain.Frontend.noDataLabel')})`}</span> : null}
            </span>
            { renderCloseButton }
        </span>
    );
};

RemovableLegend.propTypes = {
    legend: PropTypes.shape({
        name: PropTypes.any,
        tooltipOptions: PropTypes.shape({
            valueSuffix: PropTypes.string
        }),
        userOptions: PropTypes.shape({
            allSignal: PropTypes.bool,
            component: PropTypes.string,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        }),
        xData: PropTypes.array
    }),
    translate: PropTypes.func,
    multiChart: PropTypes.string
};

RemovableLegend.defaultProps = {
    multiChart: undefined
};

export default RemovableLegend;
