import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';

import './_style.scss';

const SliderComponent = ({ min, max, step, values, reverse, onChange, disabled }) => {
	const marks = {};
	marks[min] = { style: null, label: min };
	marks[max] = { style: null, label: max };

	return <div className='slider-container'>
		<Slider
			range
			min={min}
			max={max}
			step={step}
			value={values}
			reverse={reverse}
			onChange={onChange}
			disabled={disabled}
			marks={marks}
		/>
	</div>;
};

SliderComponent.propTypes = {
	min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	step: PropTypes.number,
	count: PropTypes.number,
	values: PropTypes.array,
	reverse: PropTypes.bool,
	defaultValues: PropTypes.array,
	value: PropTypes.array,
	onChange: PropTypes.func,
	disabled: PropTypes.bool
};

export default SliderComponent;
