export const rotations = [
	'rotate(0)',
	'rotate(24)',
	'rotate(48)',
	'rotate(72)',
	'rotate(96)',
	'rotate(120)',
	'rotate(144)',
	'rotate(168)',
	'rotate(192)',
	'rotate(216)',
	'rotate(240)',
	'rotate(264)',
	'rotate(288)',
	'rotate(312)',
	'rotate(336)'
];

export const PLACEMENTS = {
    CENTERED: 'centered',
    STICKY: 'sticky',
    INITIAL: 'initial'
};
