/**
 *
 * SidePanelComponent implementation
 *
 */

import React from 'react';
import './_style.scss';
import PropTypes from 'prop-types';

const SidePanelComponent = ({ children, className, compact = false }) => {
	return (
		<div
			className={`sidePanel-wrapper ${className ? className : ''} ${compact ? compact : ''}`}
			role='side-panel'
		>
			{children}
		</div>
	);
};

SidePanelComponent.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	])
};

export default SidePanelComponent;
