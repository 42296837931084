import { useLayoutEffect, useState } from 'react';

import { hasVerticalScrollbar } from './helpers';

export const useScrollBar = (element) => {
	const [scrollBarPresent, setScrollBarPresent] = useState(false);
	const [currentScrollPositions, setCurrentScrollPositions] = useState({ top: true, bottom: false });
	const [elementPosition, setElementPosition] = useState({});


	useLayoutEffect(() => {

		if (!element) {
			return;
		}
		const ro = new ResizeObserver(() => {
			setElementPosition(element.getBoundingClientRect());
			setScrollBarPresent(hasVerticalScrollbar(element));
		});
		ro.observe(element);


		const handleScroll = () => {
			const top = element.scrollTop === 0;
			const bottom = Math.ceil(element.scrollTop) >= element.scrollHeight - element.clientHeight;
			if (top || bottom) {
				setCurrentScrollPositions({ top, bottom });
			}
		};
		element.addEventListener('scroll', handleScroll);


		return () => {
			ro.unobserve(element);
			element.removeEventListener('scroll', handleScroll);
		};
	}, [element]);

	return [scrollBarPresent, currentScrollPositions, elementPosition];
};

