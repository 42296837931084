import React from 'react';

import { popupTypes } from 'helpers/constants';
import { IconError, IconInfoFilled, IconSuccess, IconWarning } from 'svgIcons/MotionPortalIcons';

import colors from 'theme/_colors.scss';

export const getClassName = (type) => {
	switch (type) {
		case popupTypes.INFO: return 'dialog-info';
		case popupTypes.SUCCESS: return 'dialog-success';
		case popupTypes.WARNING: return 'dialog-warning';
		case popupTypes.ERROR: return 'dialog-error';
		default: return 'dialog-default';
	}
};

export const getIcon = (type) => {
	const iconProps = { width: '28px', height: '28px' };

	switch (type) {
		case popupTypes.INFO: return <IconInfoFilled {...iconProps} color={colors.dodgerBlue} />;
		case popupTypes.SUCCESS: return <IconSuccess {...iconProps} color={colors.jungleGreen} />;
		case popupTypes.WARNING: return <IconWarning {...iconProps} color={colors.orangePeel} />;
		case popupTypes.ERROR: return <IconError {...iconProps} color={colors.pomegranate} />;
		default: return null;
	}
};
