import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { IconCancel } from 'svgIcons/MotionPortalIcons';
import map from 'lodash/map';
import colors from 'theme/_colors.scss';

import { GeneralButton } from 'sharedComponents/GeneralButton';

import './_style.scss';

const FileInputComponent = (props) => {
    const hiddenFileInput = useRef(null);
    const acceptedExtensions = props.acceptedExtensions && props.acceptedExtensions.length && props.acceptedExtensions.join();

    const changeFiles = (event) => {
        let files = [];
        if (event && event.target && event.target.files) {
            map(event.target.files, (file) => {
                files = [...files, file];
            });
        }
        props.onChange(files);
        event.target.value = null;
    };

    const handleFileSelectClick = () => {
        hiddenFileInput.current.click();
    };

    return <div className='file-input-container'>
        <div className='input'>
            {props.label && props.label.length > 0 && <div className='file-input-label'>
                {props.label}
            </div>}
            <div className='selected-file-container'>
                <div className='selected-file'>{map(props.value, (file, i) => {
                    return <span className='file-container' key={'fileName' + i}>
                        <span>{file.name}</span>
                        <span className='remove-button'
                            onClick={
                                () => {
                                    const newSelectedFiles = [...props.value];
                                    newSelectedFiles.splice(i, 1);

                                    if (newSelectedFiles.length) {
                                        props.onChange(newSelectedFiles);
                                    } else {
                                        props.multiple ? props.onChange([]) : props.onChange(null);
                                    }
                                }
                            }>
                            <IconCancel
                                className='cancel-button'
                                width='24px'
                                height='24px'
                                color={colors.black} />
                        </span>
                    </span>;
                })}</div>
                <GeneralButton
                    type='normal'
                    className='file-browse-button'
                    onClick={handleFileSelectClick}
                    text={props.buttonText}
                />
            </div>
            <input
                type='file'
                className='upload-input'
                ref={hiddenFileInput}
                onChange={(e) => changeFiles(e)}
                accept={acceptedExtensions}
                disabled={false}
                multiple={props.multiple}
                placeholder={props.placeholder}
            />
        </div>
    </div>;
};

FileInputComponent.propTypes = {
    onChange: PropTypes.func,
    clearable: PropTypes.bool,
    onClear: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    validationFunction: PropTypes.func,
    validationMessage: PropTypes.string,
    isRequired: PropTypes.bool,
    requireMessage: PropTypes.string,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    acceptedExtensions: PropTypes.array,
};

export default FileInputComponent;
