import { useCallback, useState } from 'react';

function useChartInstance(parentCallback) {
    const [chartInstance, setChartInstance] = useState(null);

    const callbackFn = useCallback(chart => {
        setChartInstance(chart);
        parentCallback && parentCallback(chart);
    }, []);

    const destroyCallbackFn = useCallback(() => {
        setChartInstance(null);
    }, []);

    return [chartInstance, callbackFn, destroyCallbackFn];
}

export default useChartInstance;

