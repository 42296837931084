import PropTypes from 'prop-types';
import React from 'react';
import { toLocaleDateTimeString } from 'helpers/dateHelper';
import TrendTooltipPointValueRow from './TrendTooltipPointValueRow';
import TrendTooltipMinMaxRow from './TrendTooltipMinMaxRow';

import './styles.scss';


const TrendTooltip = (props) => {
    const { points, timeStamp, dateConfig } = props;

    return (
        <div className='custom-trend-tooltip'>
            <div className='t-header'>
                {toLocaleDateTimeString(timeStamp, dateConfig)}
            </div>
            {points?.map((point, kpiIndex) => {
                const isMinMax = point?.point?.low !== undefined;
                const uom = point?.series?.tooltipOptions?.valueSuffix?.trim();
                return (
                    <div className='t-item' key={kpiIndex}>
                        {
                            isMinMax ? <TrendTooltipMinMaxRow point={point} uom={uom} />
                                : <TrendTooltipPointValueRow point={point} uom={uom} />
                        }
                    </div>
                );
            })}
        </div>
    );
};

TrendTooltip.propTypes = {
    dateConfig: PropTypes.object,
    points: PropTypes.array,
    timeStamp: PropTypes.number
};

export default TrendTooltip;
