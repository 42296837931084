import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PDFObject from 'pdfobject';
import { isSafari } from 'helpers/pdfHelper';


const PdfViewer = ({ ...props }) => {
    const { t: translate } = useTranslation();
    useEffect(() => {
        const { pdfContent, containerId } = props;
        PDFObject.embed(pdfContent, `#${containerId}`);
    }, []);

    const { width, height, containerId, pdfContent } = props;

    if (PDFObject.supportsPDFs && !isSafari()) {
        return <div style={{ width, height }} id={containerId} />;
    } else {
        return <div className='noPDFAddon'>
            {`${translate('ABB.Powertrain.Frontend.pdfViewerNotSupportedDescription')} `}
            <a href={pdfContent}>{translate('ABB.Powertrain.Frontend.pdfViewerDownloadLinkLabel')}</a>
        </div>;
    }
};

PdfViewer.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    containerId: PropTypes.string,
    pdfContent: PropTypes.string,
};

PdfViewer.defaultProps = {
    width: '700',
    height: '630',
    containerId: 'pdf-viewer',
};

export default PdfViewer;
