import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';


const ChartNoDataText = ({ title, text }) => {
    return (
        <div className='no-chart-data-text'>
            <strong>{title}</strong>
            <span>{text}</span>
        </div>
    );
};

ChartNoDataText.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
};

export default ChartNoDataText;
