import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const InfoMessage = (props) => {
    const { t: translate } = useTranslation();
    const { title, text } = props;

    return <div className='selection-info-message'>
        {title && <strong>{translate(title) || title}</strong>}
        {title && text && <div className='separator'></div>}
        {text && <span>{translate(text) || text}</span>}
    </div>;
};

InfoMessage.propTypes = {
    text: PropTypes.string,
    title: PropTypes.string
};

export default InfoMessage;
