import logger from 'helpers/logger';
import { debounce } from 'lodash';
import React, {
    forwardRef,
    memo,
    Suspense,
    useImperativeHandle,
    useRef,
    useCallback,
    useEffect
} from 'react';
import { LoadingIndicator } from 'sharedComponents';
import { CHART_PLUGINS } from './constants';

const LazyChartComponent = React.lazy(async () => {
    const { default: Highcharts } = await import(/* webpackChunkName: "highCharts" */ 'highcharts');

    // We must allow this reference in order to have dynamic, locally created svg files on the charts
    Highcharts.AST.allowedReferences.push('data:');

    // This is a patch to keep having own SVG's embedded in the highcharts
    // if an svg does not work, please check whether all it's tags and attributes are supported or not
    Highcharts.AST.allowedAttributes.push('viewBox', 'xmlns', 'stroke-linejoin', 'x2');
    Highcharts.AST.allowedTags.push('line');
    Highcharts.seriesTypes.line.prototype.drawLegendSymbol = Highcharts.seriesTypes.column.prototype.drawLegendSymbol;


    const InternalChart = (props, ref) => {
        const containerRef = useRef();
        const chartRef = useRef();
        const creatingChartRef = useRef(false);

        const loadModules = useCallback(async () => {
            if (props.modules) {
                for (const module of props.modules) {
                    let importedPlugin = null;
                    if (module === CHART_PLUGINS.NO_DATA_TO_DISPLAY) {
                        importedPlugin = await import(/* webpackChunkName: "highCharts" */ 'highcharts/modules/no-data-to-display');
                    }
                    if (module === CHART_PLUGINS.HIGHCHARTS_MORE) {
                        importedPlugin = await import(/* webpackChunkName: "highCharts" */ 'highcharts/highcharts-more');
                    }
                    if (module === CHART_PLUGINS.BOOST) {
                        importedPlugin = await import(/* webpackChunkName: "highCharts" */ 'highcharts/modules/boost');
                    }
                    if (module === CHART_PLUGINS.EXPORTING) {
                        importedPlugin = await import(/* webpackChunkName: "highCharts" */ 'highcharts/modules/exporting');
                    }
                    if (module === CHART_PLUGINS.STOCK) {
                        importedPlugin = await import(/* webpackChunkName: "highCharts" */ 'highcharts/modules/stock');
                    }
                    if (importedPlugin && importedPlugin.default) {
                        importedPlugin.default(Highcharts);
                    }
                    else {
                        logger.debug('Error loading highchart plugin:', module);
                    }
                }
            }
        }, [props.modules]);

        const createUpdateChart = useCallback(debounce((debProps) => {
            if (!chartRef.current && !creatingChartRef.current) {
                creatingChartRef.current = true;
                loadModules().then(() => {
                    // Create a chart
                    const chartInst = new Highcharts.Chart(
                        containerRef.current,
                        debProps.options,
                        debProps.callback ? debProps.callback : undefined
                    );
                    chartRef.current = chartInst;
                    logger.debug('Created chart', debProps.container);
                    creatingChartRef.current = false;
                });

            } else {
                if (chartRef.current) {
                    chartRef.current.update(
                        debProps.options,
                        true, true, false
                    );
                    logger.debug('Update chart', debProps.container);
                }
                else {
                    logger.warn('Update skipped, invalid highchart instance!');
                }
            }
        }, 100), []);

        useEffect(() => {
            return () => {
                createUpdateChart.cancel(); // cancel delayed execution
                // Destroy chart only if unmounting.
                if (chartRef.current) {
                    if (props.destroy) {
                        props.destroy(null);
                    }
                    chartRef.current?.destroy();
                    chartRef.current = null;
                    logger.debug('Chart destroyed', props.container);
                }
            };
        }, []);


        useEffect(() => {
            createUpdateChart(props);
        });

        useImperativeHandle(
            ref,
            () => ({
                get chart() {
                    return chartRef.current;
                },
                container: containerRef
            }),
            []
        );

        // Create container for the chart
        return <div {...props.containerProps} ref={containerRef} id={props.container} className='chart-style' />;
    };

    InternalChart.displayName = 'InternalChart';

    return {
        default: memo(forwardRef(InternalChart))
    };
});

const Chart = forwardRef((props, ref) => {
    return <Suspense fallback={<LoadingIndicator />}>
        <LazyChartComponent {...props} ref={ref} />
    </Suspense>;
});

Chart.displayName = 'LazyChartWrapper';

export default Chart;
