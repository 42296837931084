import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 *  Higher order component
 * 	Provides a visible boolean variable and two functions to manipulate component render
 *  Also has an own prop for setting whether or not on switch it should scroll to top
 */
const withVisibilityControl = (WrappedComponent) => {

	function ComponentWithVisibilityControl(props) {
		const { scrollToTopWhenOpened } = props;

		const [visible, setVisible] = useState(false);
		const [scrollbackPos, setScrollbackPos] = useState(0);
		const controlledDivRef = useRef();

		const handleClickOutsideControlledDiv = event => {
			if (controlledDivRef.current && !controlledDivRef.current.contains(event.target)) {
				setVisible(false);
			}
		};

		/**
		 * Switches the visibility on
		 * Also, if scrollToTopWhenOpened=true, saves current position and scrolls to top
		 */
		const setVisibilityOn = () => {
			setVisible(true);
			if (scrollToTopWhenOpened) {
				setScrollbackPos(window.pageYOffset);
				window.scrollTo(0, 0);
			}
		};

		/**
		 * Switches the visibility off
		 * Also, if scrollToTopWhenOpened=true, returns to saved position
		 */
		const setVisibilityOff = () => {
			setVisible(false);
			if (scrollToTopWhenOpened) {
				window.scrollTo(0, scrollbackPos);
				setScrollbackPos(0);
			}
		};

		/**
		 *	Click event management
		 */
		useEffect(() => {
			document.addEventListener('mousedown', handleClickOutsideControlledDiv);

			return () => {
				document.removeEventListener('mousedown', handleClickOutsideControlledDiv);
			};
		}, []);

		// Package to enchance WrappedComponent with
		const visibilityControl = { visible, setVisibilityOn, setVisibilityOff };

		return <WrappedComponent ref={controlledDivRef} {...props} visibilityControl={visibilityControl} />;
	}

	ComponentWithVisibilityControl.defaultProps = {
		scrollToTopWhenOpened: true
	};

	ComponentWithVisibilityControl.propTypes = {
		scrollToTopWhenOpened: PropTypes.bool,
	};

	return ComponentWithVisibilityControl;
};

export default withVisibilityControl;
