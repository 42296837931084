import React, { useState, useEffect } from 'react';
import logger from 'helpers/logger';
import { ConfiguredNotificationsApi } from 'api/index';
import { GeneralDialog, LoadingIndicator } from 'sharedComponents';
import { translate } from 'helpers/translateHelper';


/**
 *  Higher order component
 *  Fetches broadcasted messages from api
 */
const withFetchedMessages = (WrappedComponent) => {

    const FetchedBroadcastedMessageModal = (props) => {

        const [lastMessagePath, setLastMessagePath] = useState(null);
        const [loadedMessage, setLoadedMessage] = useState(false);

        const getLatestMessage = (messages) => {
            // First gets the valid messsages, then the latest of them
            return messages
                .filter((message) => {
                    const { startDate, endDate, messagePath } = message;

                    if (!startDate || !endDate || !messagePath) {
                        return false;
                    }

                    const start = new Date(startDate);
                    const end = new Date(endDate);
                    const now = new Date();

                    return start <= now && now <= end;
                })
                .sort((message1, message2) => message1.createdOn > message2.createdOn ? -1 : 1)[0];
        };

        useEffect(() => {
            props.show && ConfiguredNotificationsApi.getBroadcastedMessages()
                .then((broadcastedMessages) => {
                    if (broadcastedMessages) {
                        const message = getLatestMessage(broadcastedMessages);
                        if (message && message.messagePath) {
                            setLastMessagePath(message.messagePath);
                        }
                    }
                    setLoadedMessage(true);
                })
                .catch((err) => {
                    err && logger.error(err.message);
                    setLoadedMessage(true);
                });

            return () => {
                setLoadedMessage(false);
            };
        }, [props.show]);


        return (
            lastMessagePath ?
                < WrappedComponent {...props} broadcastedMessagePath={lastMessagePath} />
                :
                <GeneralDialog
                    {...props}
                    modalClassName='dropdown-modals modal-pdf'
                    close={props.closeModal}
                    closeButton={true}
                    persistent={false}
                    closeOnAcceptOnly={false}>
                    <br />
                    {loadedMessage
                        ? <b>{translate('ABB.Powertrain.Frontend.broadcastNoNotificationLabel')}</b>
                        : <LoadingIndicator size='medium' />
                    }
                </GeneralDialog>
        );
    };

    return FetchedBroadcastedMessageModal;
};

export default withFetchedMessages;
