import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    IconChevronLeft,
    IconChevronRight,
} from 'svgIcons/MotionPortalIcons';
import './styles.scss';
import colors from 'theme/_colors.scss';


const HorizontalScrollComponent = (props) => {
    const horizontalScroll = useRef(null);
    const [scrollX, setscrollX] = useState(0);
    const [scrollEnd, setscrollEnd] = useState(false);

    const scrollEndSetting = () => {
        if (
            Math.floor(horizontalScroll.current.scrollWidth - horizontalScroll.current.scrollLeft) <=
            horizontalScroll.current.offsetWidth
        ) {
            setscrollEnd(true);
        } else {
            setscrollEnd(false);
        }
    };

    const slide = (shift) => {
        horizontalScroll.current.scrollLeft += shift;
        setscrollX(scrollX + shift);
        scrollEndSetting();
    };

    const scrollCheck = () => {
        setscrollX(horizontalScroll.current.scrollLeft);
        scrollEndSetting();
    };

    useEffect(() => {
        window.addEventListener('resize', scrollCheck);

        return () => {
            window.removeEventListener('resize', scrollCheck);
        };
    }, []);

    useEffect(() => {
        scrollCheck();
    }, [props.children]);

    const renderLeftArrow = () => {
        return <div className={scrollX === 0 ? 'horizontal-arrow-hidden' : 'horizontal-arrow-prev'} >
            {<div onClick={scrollX === 0 ? undefined : () => slide(-200)}>
                <IconChevronLeft width='20px' height='20px' color={colors.silverChalice} />
            </div>}
        </div>;
    };

    const renderRightArrow = () => {
        return <div className={scrollEnd ? 'horizontal-arrow-hidden' : 'horizontal-arrow-next'} >
            {<div onClick={scrollEnd ? undefined : () => slide(200)}>
                <IconChevronRight width='20px' height='20px' color={colors.silverChalice} />
            </div>}
        </div>;
    };

    return <div className='horizontal-scroll-container'>
        {renderLeftArrow()}
        <ul ref={horizontalScroll} onScroll={scrollCheck} className='horizontal-scrollable-content'>
            {props.children}
        </ul>
        {renderRightArrow()}
    </div>;
};

HorizontalScrollComponent.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
};

export default HorizontalScrollComponent;
