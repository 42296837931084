import React from 'react';
import PropTypes from 'prop-types';

function PredefinedSelection({ label, numberOfDaysBack, handleClick, numberOfHoursBack }) {
	return (
		<div className='calendar-range-item' onClick={() => handleClick(numberOfDaysBack, numberOfHoursBack)}>
			<span className='item-text'>{label}</span>
		</div>
	);
}

PredefinedSelection.propTypes = {
	label: PropTypes.string,
	numberOfDaysBack: PropTypes.number,
	handleClick: PropTypes.func
};

export default PredefinedSelection;
