import { useEffect, useState } from 'react';
import { addCustomZoom, addUniqueScaling } from './helpers';

function useCustomizedOptions(chartUserOptions,
    enableUniqueScaling,
    uniqueScalingSwitched,
    selectionCallbackFn) {

    const [chartOptions, setChartOptions] = useState(chartUserOptions);

    useEffect(() => {
        let newOptions = addCustomZoom(chartUserOptions, selectionCallbackFn);
        if (enableUniqueScaling && chartUserOptions?.yAxis?.length) {
            if (uniqueScalingSwitched) {
                newOptions = addUniqueScaling(newOptions);
            }
        }
        setChartOptions(newOptions);
    }, [uniqueScalingSwitched, chartUserOptions]);

    return [chartOptions];
}

export default useCustomizedOptions;

