export const config = {
    LARGE: {
        width: 128,
        height: 128,
        r: '51.2',
        cx: 64,
        cy: 64,
        strokeDasharray: '321.7px',
        strokeDashoffset: '321.7px',
        size: 'Large',
        className: 'ABB_CommonUX_LoadingIndicator__root ABB_CommonUX_LoadingIndicator__light ABB_CommonUX_LoadingIndicator__radial ABB_CommonUX_LoadingIndicator__indeterminate ABB_CommonUX_LoadingIndicator__large ABB_CommonUX_LoadingIndicator__blue'
    },
    MEDIUM: {
        width: 64,
        height: 64,
        r: '25.6',
        cx: 32,
        cy: 32,
        strokeDasharray: '160.8px',
        strokeDashoffset: '160.8px',
        size: 'Medium',
        className: 'ABB_CommonUX_LoadingIndicator__root ABB_CommonUX_LoadingIndicator__light ABB_CommonUX_LoadingIndicator__radial ABB_CommonUX_LoadingIndicator__indeterminate ABB_CommonUX_LoadingIndicator__medium ABB_CommonUX_LoadingIndicator__blue'
    },
    SMALL: {
        width: 32,
        height: 32,
        r: '12.8',
        cx: 16,
        cy: 16,
        strokeDasharray: '80.4px',
        strokeDashoffset: '80.4px',
        size: 'Small',
        className: 'ABB_CommonUX_LoadingIndicator__root ABB_CommonUX_LoadingIndicator__light ABB_CommonUX_LoadingIndicator__radial ABB_CommonUX_LoadingIndicator__indeterminate ABB_CommonUX_LoadingIndicator__small ABB_CommonUX_LoadingIndicator__blue'
    },
    XSMALL: {
        width: 16,
        height: 16,
        r: '6.4',
        cx: 8,
        cy: 8,
        strokeDasharray: '40.2px',
        strokeDashoffset: '40.2px',
        size: 'XSmall',
        className: 'ABB_CommonUX_LoadingIndicator__root ABB_CommonUX_LoadingIndicator__light ABB_CommonUX_LoadingIndicator__radial ABB_CommonUX_LoadingIndicator__indeterminate ABB_CommonUX_LoadingIndicator__xsmall ABB_CommonUX_LoadingIndicator__blue'
    }
};
