import { createSelector } from 'reselect';

const getNotifications = ({ generalNotification }) => {
	return generalNotification.data;
};

export const getModel = createSelector([
	getNotifications
], (
	notifications
) => {
	return {
		notifications
	};
});
