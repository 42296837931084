import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LoadingStatusWrapper } from 'sharedComponents';
import { apiCallStatus } from 'helpers/constants';
import './_style.scss';


const ScrollableListComponent = ({
    children,
    withLoading,
    loadingStatus,
    emptyLabel,
    hasMore,
    loadMore,
    pageSize,
    className
}) => {
    const listInnerRef = useRef();

    useEffect(() => {
        if (pageSize && children?.length <= pageSize && listInnerRef?.current?.scrollTo) {
            // if first page is loaded, jump back to the top of the list
            // otherwise scroll position is kept
            listInnerRef?.current?.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        }
    }, [children?.length]);

    const renderChildren = () => children?.length > 0 ?
        children :
        loadingStatus !== apiCallStatus.LOADING &&
        <div className='scrollable-list_empty-label'>{emptyLabel}</div>;

    const renderContent = () => withLoading ?
        <LoadingStatusWrapper
            transparent
            useCustomAnchor
            loadingStatus={loadingStatus}
        >
            <div className='scrollable-list-with-loading'>
                {renderChildren()}
            </div>
        </LoadingStatusWrapper> :
        renderChildren();

    const onScroll = () => {
        if (hasMore && loadMore && typeof loadMore === 'function' &&
            loadingStatus !== apiCallStatus.LOADING &&
            listInnerRef.current) {
            const {
                scrollTop,
                scrollHeight,
                clientHeight
            } = listInnerRef.current;

            if (Math.round(scrollTop) + clientHeight === scrollHeight) {
                loadMore();
            }
        }
    };

    return <div
        className={`scrollable-list ${className} ${loadingStatus === apiCallStatus.LOADING ? 'loading' : ''}`}
        role='scrollable-list'
        onScroll={onScroll}
        ref={listInnerRef}>
        {renderContent()}
    </div>;
};

ScrollableListComponent.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    withLoading: PropTypes.bool,
    loadingStatus: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    emptyLabel: PropTypes.string,
    hasMore: PropTypes.bool,
    loadMore: PropTypes.func,
    pageSize: PropTypes.number,
    className: PropTypes.string
};

export default ScrollableListComponent;
