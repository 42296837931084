import React from 'react';
import PropTypes from 'prop-types';

import './_style.scss';

const TextAreaComponent = ({ value, onChange, readonly, label, cols, rows, className, placeholder, resizable=true, maxLength }) => {
	return <div className={`text-area-container ${className ? className : ''}`}>
		<div className='text-area-description'>{label}</div>
		<textarea
			className='text-area-component'
			style={{resize: resizable ? 'vertical' : 'none'}}
			value={value}
			onChange={(e) => onChange(e.target.value)}
			disabled={readonly}
			cols={cols}
			rows={rows}
			placeholder={placeholder}
			maxLength={maxLength}
		/>
	</div>;
};

TextAreaComponent.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	readonly: PropTypes.bool,
	label: PropTypes.string,
	cols: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	className: PropTypes.string
};

export default TextAreaComponent;
