import React from 'react';
import PropTypes from 'prop-types';
import { apiCallStatus } from 'helpers/constants';
import { LoadingIndicator } from 'sharedComponents/PowertrainLoadingIndicator';
import useLoadingSummary from './useLoadingSummary';

import './style.scss';
import { Modal } from 'react-bootstrap';

const LoadingStatusWrapper = (props) => {

    const {
        loadingStatus,
        debounce = true,
        children,
        transparent,
        indicatorConfig,
        fullScreen,
        noIndicator,
        useCustomAnchor // if this property is true, then the loading indicator is anchored
        // to its nearest parent element with a relative position,
        //  if none is found it anchors itself to the body element
    } = props;

    const loadingSummary = useLoadingSummary(null, loadingStatus, debounce);

    const isLoading = () => loadingSummary === apiCallStatus.LOADING;


    const renderLoaderInComponent = () => {

        const wrapperClassName = `loading-wrapper ${transparent ? 'transparent' : ''}`;

        const wrappedLoadingIndicator = <div className={wrapperClassName}>{noIndicator ? null : <LoadingIndicator {...indicatorConfig} />}</div>;

        return useCustomAnchor ? wrappedLoadingIndicator : <div className={'loading-anchor'}>{wrappedLoadingIndicator}</div>;
    };


    const renderFullScreenLoader = () => {
        return <Modal show={true} fullscreen={true} dialogClassName='loading-modal' backdrop={false}>
            <Modal.Body><div className={`full-screen ${transparent ? 'transparent' : ''}`}>
                <LoadingIndicator {...indicatorConfig} /></div>
            </Modal.Body>
        </Modal>;
    };

    const renderLoader = () => fullScreen ? renderFullScreenLoader() : renderLoaderInComponent();

    const renderContent = () => !isLoading() && children;

    const renderChildren = () => transparent ? children : renderContent();

    return <React.Fragment>
        {isLoading() && renderLoader()}
        {renderChildren()}
    </React.Fragment>;
};

LoadingStatusWrapper.propTypes = {
    children: PropTypes.any,
    loadingStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    indicatorConfig: PropTypes.object,
    transparent: PropTypes.bool,
    fullScreen: PropTypes.bool,
    useCustomAnchor: PropTypes.bool
};


LoadingStatusWrapper.displayName = LoadingStatusWrapper;

export default LoadingStatusWrapper;
