import React from 'react';
import PropTypes from 'prop-types';

import { degradedMode } from './constants';

import {
    IconDegradedBatteryFull,
    IconDegradedModeOne,
    IconDegradedModeTwo,
    IconDegradedBatteryEmpty
} from 'svgIcons/MotionPortalIcons';

const DegradedModeBattery = ({ value }) => {
    const getDegradedModeBattery = (value) => {
        switch (value) {
            case degradedMode.DEGRADED_BATTERY_FULL:
                return <IconDegradedBatteryFull />;
            case degradedMode.DEGRADED_MODE_ONE:
                return <IconDegradedModeOne />;
            case degradedMode.DEGRADED_MODE_TWO:
                return <IconDegradedModeTwo />;
            case degradedMode.DEGRADED_BATTERY_EMPTY:
                return <IconDegradedBatteryEmpty />;
            default:
                return null;
        }
    };

    return getDegradedModeBattery(value);
};

DegradedModeBattery.propTypes = {
    value: PropTypes.string,
};

export default DegradedModeBattery;

