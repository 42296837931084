import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { delay } from 'lodash';

const ScrollToElementWrapper = ({
    children,
    condition,
    smooth,
    delayMillisecond = 100,
    customTrigger = () => false
}) => {

    const ref = useRef();
    useEffect(() => {
        if (condition && ref.current) {
            delay(() => ref.current?.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' }), delayMillisecond);
        }
    }, []);

    const shouldScroll = customTrigger();

    useEffect(() => {
        if (condition && ref.current && shouldScroll) {
            delay(() => ref.current?.scrollIntoView({ behavior: 'smooth' }), 450); //Delay is needed to make sure the dropdown animation is finished.
        }
    }, [shouldScroll]);


    return <div ref={ref}>
        {children}
    </div>;
};

ScrollToElementWrapper.propTypes = {
    condition: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    smooth: PropTypes.bool,
    delayMillisecond: PropTypes.number
};

export default ScrollToElementWrapper;
