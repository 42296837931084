import findKey from 'lodash/findKey';

export const findTabWithRoute = (tabs, pathname) => {
	//const currentTab = findKey(tabs, (tab) => pathname.indexOf(tab.route) >= 0);
	const currentTab = findKey(tabs, (tab) => pathname?.toLowerCase() === tab?.route?.toLowerCase());
	//console.log('The current Tab find', currentTab);
	//console.log('The All Tabs Present', tabs);
	//console.log('The Path Name ........', pathname);
	return currentTab;
};

export const getDefaultTab = (tabOptions, pathname, defaultTab) => {
	const tabFromRoute = findTabWithRoute(tabOptions, pathname);

	return tabFromRoute ? tabFromRoute : defaultTab;
};
