export const allPredefinedDaySelections = [
    {
        numberOfDaysBack: 1,
        numberOfHoursBack: 24,
        label: 'ABB.Powertrain.Frontend.datePickerLastDayLbl'
    },
    {
        numberOfDaysBack: 7,
        label: 'ABB.Powertrain.Frontend.datePickerLast7DaysLbl'
    },
    {
        numberOfDaysBack: 14,
        label: 'ABB.Powertrain.Frontend.datePickerLast14DaysLbl'
    },
    {
        numberOfDaysBack: 30,
        label: 'ABB.Powertrain.Frontend.datePickerLast30DaysLbl'
    },
    {
        numberOfDaysBack: 90,
        label: 'ABB.Powertrain.Frontend.datePickerLast90DaysLbl'
    }
];
export const allPredefinedDaySelections365 = [
    {
        numberOfDaysBack: 1,
        numberOfHoursBack: 24,
        label: 'ABB.Powertrain.Frontend.datePickerLastDayLbl'
    },
    {
        numberOfDaysBack: 7,
        label: 'ABB.Powertrain.Frontend.datePickerLast7DaysLbl'
    },
    {
        numberOfDaysBack: 14,
        label: 'ABB.Powertrain.Frontend.datePickerLast14DaysLbl'
    },
    {
        numberOfDaysBack: 30,
        label: 'ABB.Powertrain.Frontend.datePickerLast30DaysLbl'
    },
    {
        numberOfDaysBack: 90,
        label: 'ABB.Powertrain.Frontend.datePickerLast90DaysLbl'
    },
    {
        numberOfDaysBack: 365,
        label: 'ABB.Powertrain.Frontend.datePickerLast365DaysLbl'
    }
];
