import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';

/** General Tabs component
 *
 * @param {variant} - Allowed values: "primary", "secondary", "panel"
 * @param {children} - Renderable children. Normally either result from Router switch or selected component from options object
 * @param {options} - Object with tab options
 * @param {handleTabSelection}
 * @param {activatedTabKey} - Object key from options
 */

export const GeneralTabContent = ({ variant, children, options, handleTabSelection, activatedTabKey, activeDetailedInformationTab }) => {
    const { t: translate } = useTranslation();
    const tabOption = (option, optionKey) => {
        const isDetailedInformationTab = activeDetailedInformationTab && option.tabID === translate('ABB.Powertrain.Frontend.ptAssetDetailsDetailedInformationTabID');
        return (
            <div
                className={`tab ${optionKey === activatedTabKey || isDetailedInformationTab ? 'selected' : ''} ${option.disabled ? 'disabled' : ''}`}
                id={optionKey}
                onClick={handleTabSelection}
                disabled={option.disabled}
                key={optionKey}
                role='tab'
            >
                {option?.titleIcon && <span className='tab-icon'>{option.titleIcon}</span>}
                {option.title}
            </div>
        );
    };

    return (
        <div className='tab-container'>
            <div className={`tab-line + ${variant}`}>
                {map(options, (option, optionKey) => option.hidden ? null : tabOption(option, optionKey))}
            </div>
            <div className='tab-content'>{children}</div>
        </div>
    );
};

GeneralTabContent.propTypes = {
    variant: PropTypes.oneOf(['primary', 'secondary', 'panel']),
    children: PropTypes.node,
    handleTabSelection: PropTypes.func,
    options: PropTypes.oneOfType([
        PropTypes.objectOf(
            PropTypes.shape({
                title: PropTypes.string,
                titleIcon: PropTypes.oneOf([PropTypes.node, PropTypes.any]),
                disabled: PropTypes.oneOf([PropTypes.bool, PropTypes.any]),
                hidden: PropTypes.oneOf([PropTypes.bool, PropTypes.any]),
                component: PropTypes.oneOf([PropTypes.node, PropTypes.any]),
            })
        ),
        PropTypes.any,
    ]),
    activatedTabKey: PropTypes.string,
};
