import * as actionTypes from './constants';
import filter from 'lodash/filter';

const initialState = {
	data: []
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.SET_NOTIFICATION:
			return {
				...state,
				data: [...state.data, { ...action.data, id: new Date().getTime() + Math.random() }]
			};
		case actionTypes.CLOSE_NOTIFICATION:
			return {
				...state,
				data: filter(state.data, (item) => item.id !== action.data.id)
			};
		default:
			return state;
	}
}
