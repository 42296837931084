import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { IconDropDown } from 'svgIcons/MotionPortalIcons';

import { scrollTo } from './helpers';
import { useScrollBar } from './useScrollBar';

import './style.scss';

const FastScrollButton = (props) => {

	const { scrollableRef, width = 32, height=32 } = props;
	const { current: element } = scrollableRef;

	const [iconClass, setIconClass] = useState();
	const [scrollbarPresent, scrollBarPosition, elementPosition] = useScrollBar(element);


	useEffect(() => {
		if (scrollBarPosition.top) {
			setIconClass('icon');
		}
		else if (scrollBarPosition.bottom) {
			setIconClass('icon icon-rotated');
		}
	}, [scrollBarPosition]);

	return element && scrollbarPresent
		?
		<div className={`${iconClass} fast-scroll-button`}
			onClick={() => scrollTo({ element, scrollBarPosition })}
			style={{
				width,
				height,
				left: `calc(${elementPosition.right}px - ${width}px)`,
				bottom: `calc(100vh - ${elementPosition.bottom}px)`
			}}
		>
			{
				<IconDropDown width={width} height={height} />
			}
		</div>
		:
		null;
};

FastScrollButton.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scrollableRef: PropTypes.shape({
	current: PropTypes.instanceOf(Element)
  }),
};

export default FastScrollButton;
