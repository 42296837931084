import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import { GeneralButton } from 'sharedComponents/GeneralButton';
import { getClassName, getIcon } from './helpers';

import './style.scss';


const GeneralDialogComponent = ({
    show,
    close,
    title,
    closeButton,
    notificationType,
    cancelButtonProps,
    acceptButtonProps,
    modalClassName,
    dialogClassName,
    backdropClassName,
    id,
    persistent,
    children
}) => {
    const headerIcon = getIcon(notificationType);
    const staticBackdropProps = persistent ? {
        backdrop: 'static',
        keyboard: false
    } : {};

    return (
        <Modal
            className={'general-dialog-container ' + (modalClassName || '')}
            dialogClassName={`general-dialog ${getClassName(notificationType)} ${dialogClassName || ''}`}
            backdropClassName={backdropClassName || 'general-dialog-backdrop'}
            id={id}
            show={show}
            onHide={close}
            animation={false}
            {...staticBackdropProps}
        >
            {<Modal.Header closeButton={closeButton}>
                <div className='general-dialog-header'>
                    {headerIcon && <div className='general-dialog-icon'>{headerIcon}</div>}
                    <div className='general-dialog-title'>{title}</div>
                </div>
            </Modal.Header>}
            {children && <Modal.Body>{children}</Modal.Body>}
            <Modal.Footer>
                {cancelButtonProps && <GeneralButton
                    type='discreet'
                    className='dialog-cancel-button'
                    text={cancelButtonProps.text}
                    onClick={() => {
                        cancelButtonProps.onClick && cancelButtonProps.onClick();
                        !cancelButtonProps.keepOpenOnClick && close();
                    }}
                    disabled={cancelButtonProps.disabled}
                />}
                {acceptButtonProps && <GeneralButton
                    type='primary'
                    className={acceptButtonProps.className || 'dialog-accept-button'}
                    onClick={acceptButtonProps.onClick}
                    text={acceptButtonProps.text}
                    disabled={acceptButtonProps.disabled}
                />}
            </Modal.Footer>
        </Modal>
    );
};

GeneralDialogComponent.propTypes = {
    acceptButtonProps: PropTypes.oneOfType([
        PropTypes.shape({
            className: PropTypes.any,
            disabled: PropTypes.any,
            onClick: PropTypes.any,
            text: PropTypes.string.isRequired
        }),
        PropTypes.any
    ]),
    cancelButtonProps: PropTypes.oneOfType([
        PropTypes.shape({
            className: PropTypes.string,
            text: PropTypes.string,
            onClick: PropTypes.any,
            keepOpenOnClick: PropTypes.bool,
        }),
        PropTypes.any
    ]),
    children: PropTypes.any,
    close: PropTypes.any,
    notificationType: PropTypes.any,
    show: PropTypes.any,
    title: PropTypes.any,
    persistent: PropTypes.bool
};

export default GeneralDialogComponent;
