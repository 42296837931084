import React from 'react';
import PropTypes from 'prop-types';
require('./styles.scss');


const RadioButtonComponent = ({ id, disabled, onClick, checked, label, labelIcon, large = false, }) => {
    const iconSize = large ? '32px' : '16px';

    return <div
        id={id}
        className={disabled ? 'radio-button disabled' : 'radio-button'}
        onClick={() => {
            if (typeof onClick === 'function' &&
                !disabled) {
                onClick();
            }
        }}
    >
        <svg width={iconSize} height={iconSize} viewBox={'0 0 48 48'} xmlns='http://www.w3.org/2000/svg'>
            <g className={checked ? 'checked' : 'empty'} strokeLinecap='square' strokeLinejoin='round'>
                <circle cx='23.765' cy='23.771' r='20.833' />
                {checked && <circle cx='23.898' cy='23.638' r='10' />}
            </g>
        </svg>
        <div className='radio-button-label'>
            {labelIcon && <div className='radio-button-label-icon'>{labelIcon}</div>}
            {label && <span className={`radio-button-label-text ${large ? 'large' : ''}`}>{label}</span>}
        </div>
    </div>;
};

RadioButtonComponent.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.any,
    labelIcon: PropTypes.any,
    checked: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    large: PropTypes.bool
};

export default RadioButtonComponent;
