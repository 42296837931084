import * as actionTypes from './constants';

const closeNotificationAction = (data) => {
	return {
		type: actionTypes.CLOSE_NOTIFICATION,
		data
	};
};

export const closeNotification = (notification) => {
	return (dispatch) => {
		dispatch(closeNotificationAction(notification));
	};
};
