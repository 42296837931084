import { useCallback, useEffect, useState } from 'react';
import { isArray, debounce } from 'lodash';
import { getLoadingSummary } from 'helpers/loadingStatusHelper';
import { apiCallStatus } from 'helpers/constants';

const useLoadingSummary = (callbackFn, loadingStatus, withDebounce = true) => {

	const [debouncedLoadingSummary, setDebouncedLoadingSummary] = useState(apiCallStatus.LOADING);

	const isArrayLoadingStatus = isArray(loadingStatus);
	const depArray = isArrayLoadingStatus ? loadingStatus : [loadingStatus];

	const debCallback = useCallback(isArrayLoadingStatus && debounce((ls) => {
		const summary = getLoadingSummary(ls);
		callbackFn && callbackFn(summary);
		setDebouncedLoadingSummary(summary);
	}, withDebounce ? 100 : 0, { maxWait: 750 }), []);

	useEffect(() => {
		isArrayLoadingStatus && debCallback(depArray);
	}, [...depArray]);

	useEffect(() => {
		return () => isArrayLoadingStatus && debCallback.cancel();
	}, []);

	return isArrayLoadingStatus ? debouncedLoadingSummary : getLoadingSummary(loadingStatus);
};

export default useLoadingSummary;
