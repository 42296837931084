import PropTypes from 'prop-types';
import React from 'react';

const HistogramTooltip = ({ component, name, xUnit, yUnit, x, y }) => {

    return <>
        {component ? <>
            <b>{component}</b>
            <br /> {name}
        </>
            : <b>{name}</b>}
        <br />
        {y} {yUnit}<br />
        {x} {xUnit}

    </>;
};


HistogramTooltip.propTypes = {
    component: PropTypes.string,
    name: PropTypes.string,
    xUnit: PropTypes.string,
    yUnit: PropTypes.string,
    x: PropTypes.string,
    y: PropTypes.number
};

export default HistogramTooltip;
