import React from 'react';
import map from 'lodash/map';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getModel } from './selectors';
import * as actions from './actions';

import GeneralNotification from './GeneralNotification';
import './style.scss';

const GeneralNotificationContainer = ({model, actions}) => {
	return model.notifications.length ? <div className='general-notification-container'>
		{map(model.notifications, (notification) => <GeneralNotification {...model} {...actions} notification={notification} key={notification.id}/>)}
	</div> : null;
};


const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

const GeneralNotificationContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(GeneralNotificationContainer);

export default GeneralNotificationContainerConn;
