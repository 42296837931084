import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { PDFViewer, GeneralDialog } from 'sharedComponents';
import { pdfReaderInstalled } from 'helpers/pdfHelper';

import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';


const PdfModal = ({
    show,
    closeModal,
    children,
    closable = true,
    modalClasses = null,
    title,
    pdfContent,
    acceptButtonProps = null,
    persistent = false,
    closeOnAcceptOnly = false,
    loadingIndicatorStatus = null
}) => {
    const { t: translate } = useTranslation();
    const closeFunction = closable ? closeModal : () => { };

    return pdfContent && pdfContent.length
        && <GeneralDialog
            show={show}
            modalClassName={`dropdown-modals modal-pdf ${modalClasses}`}
            close={closeFunction}
            title={title}
            closeButton={closable && !closeOnAcceptOnly}
            acceptButtonProps={acceptButtonProps}
            persistent={persistent}
            closeOnAcceptOnly={closeOnAcceptOnly}
        >
            <LoadingStatusWrapper loadingStatus={loadingIndicatorStatus}>
                {
                    pdfReaderInstalled() ?
                        <PDFViewer pdfContent={pdfContent} />
                        :
                        <div className='noPDFAddon'>
                            {`${translate('ABB.Powertrain.Frontend.pdf_no_addon_installed')} `}
                            <a href={pdfContent}>{translate('ABB.Powertrain.Frontend.pdfViewerDownloadLinkLabel')}</a>
                        </div>}
                {children}
            </LoadingStatusWrapper>
        </GeneralDialog>;
};


PdfModal.propTypes = {
    show: PropTypes.bool,
    closeModal: PropTypes.func,
    children: PropTypes.any,
    closable: PropTypes.bool,
    modalClasses: PropTypes.any,
    title: PropTypes.string,
    pdfContent: PropTypes.string,
};

export default PdfModal;

