import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { IconClose } from 'svgIcons/MotionPortalIcons';
import { getNotificationIcon } from 'helpers/notificationIconHelper';
import colors from 'theme/_colors.scss';
import './style.scss';

const GeneralNotification = ({ notification, ...props }) => {
    const [timerId, setTimerId] = useState(null);

    const clearActiveTimer = () => {
        if (timerId) {
            clearTimeout(timerId);
            setTimerId(null);
        }
    };

    const onClose = () => {
        clearActiveTimer();
        props.closeNotification(notification);
        if (props.onClose) {
            props.onClose(notification);
        }
    };

    useEffect(() => {
        if (!notification.sticky) {
            setTimerId(setTimeout(() => {
                onClose();
            }, notification.timeout));
        }
        return () => {
            clearActiveTimer();
        };
    }, []);

    return (
        <div className={`notification-bar ${notification.type}-bar`}>
            <div>
                {getNotificationIcon(notification.type)}
                <span className='scrollbar-primary'>{notification.message}
                    {notification.link && <a className='link' href={notification.link.url} rel='noreferrer' target='_blank'>{notification.link.description}</a>}
                </span>
            </div>
            <div
                id='closeNotifyButton'
                role='presentation'
                onClick={onClose}
            >
                <IconClose
                    color={colors.white}
                    strokeWidth='6'
                />
            </div>
        </div>
    );
};

GeneralNotification.displayName = 'GeneralNotification';

GeneralNotification.propTypes = {
    notification: PropTypes.shape({
        link: PropTypes.shape({
            description: PropTypes.any,
            url: PropTypes.any
        }),
        message: PropTypes.any,
        type: PropTypes.any
    }).isRequired,
    props: PropTypes.shape({
        onClose: PropTypes.func,
        closeNotification: PropTypes.func,
        sticky: PropTypes.bool
    })
};

export default GeneralNotification;

