import React from 'react';
import Tippy from '@tippy.js/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IconInfo, IconLink } from 'svgIcons/MotionPortalIcons';
import { onlineHelpLinks } from 'helpers/constants';

import './style.scss';
import { getEnvironmentStageSelector } from 'helpers/selectors';

const InfoButtonWithOnlineHelp = (props) => {

    const { i18n } = useTranslation();
    const environment = useSelector(getEnvironmentStageSelector);
    const languageCode = i18n.language;

    return <div className='info-button-with-online-help' role='tooltip'>
        <Tippy
            content={<React.Fragment>
                {props.infoText}
                {!props.withoutLink && <a className='description-link' rel='noreferrer' target='_blank' href={`${onlineHelpLinks[environment]}${languageCode}`} role='link' >
                    <IconLink width='16' height='16' viewBox='0 0 16 16' />
                </a>}
            </React.Fragment>}
            className='info-button-with-online-help-tippy'
            interactive={true}
            interactiveBorder={10}
            animation='scale'
            theme='light-border'
            trigger='click'
            placement='bottom'
            maxWidth='400px'
            boundary='viewport'
        >
            <div role='iconButton' >
                <IconInfo width={props.width} height={props.width} color={'black'} />
            </div>
        </Tippy>
    </div>;
};

export default InfoButtonWithOnlineHelp;

