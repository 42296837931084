import { getUTCDateString } from 'helpers/dateHelper';
import { useCallback, useEffect } from 'react';
import { setChartBounds, saveSeriesSelection, updateZoomLevel } from './helpers';

const useCustomZoom = (props, mainChartInst, popOutChartInst, showChartPopout, zoomOut, setZooming) => {
    const { zoomInServer, resetServerZoom, isZoomed, setIsZoomed, hasKpi } = props;

    useEffect(() => {
        setZooming(false);
    }, [popOutChartInst]);

    useEffect(() => {
        if (!isZoomed) {
            mainChartInst?.container && hasKpi && zoomOut(mainChartInst);
            popOutChartInst?.container && hasKpi && zoomOut(popOutChartInst);
        }
    }, [isZoomed, mainChartInst, popOutChartInst]);

    const selectionCallbackFn = useCallback(function (event) {
        if (zoomInServer && resetServerZoom) { // perform server zooming
            setChartBounds(this.xAxis); // reset scale (extremes) of the axes in order to scale automatically
            setChartBounds(this.yAxis);
            if (!event.resetSelection) {
                const xAxis = event.xAxis[0];
                const minDate = new Date(xAxis.min);
                minDate.setDate(minDate.getDate());
                zoomInServer(getUTCDateString(minDate), getUTCDateString(new Date(xAxis.max)));
                setIsZoomed(true);
            }
            return false; // prevent default zoom in case of server zoom
        }
        else if (!event.resetSelection) { // perform client zooming
            setIsZoomed(true);
        }
    }, [props.options]);

    useEffect(() => {
        if (!props.options.enableNoDataZoom) {
            if (props.options.series?.length === 0) {
                if (!resetServerZoom) {
                    setIsZoomed(false);
                }
            }
        }
    }, [props.options.series?.length, mainChartInst]);

    useEffect(() => {
        if (showChartPopout && popOutChartInst) { // open popout chart & zoomed
            saveSeriesSelection(popOutChartInst, mainChartInst);
            if (isZoomed) {
                updateZoomLevel(popOutChartInst, mainChartInst);
            }
        }
    }, [popOutChartInst]);

    return [selectionCallbackFn];
};

export default useCustomZoom;
