import { getUTCDateString, formatStartDate, formatEndDate } from 'helpers/dateHelper';
import { DAY_IN_MS } from 'helpers/constants';

// Default date range selection from MAX_DAYS_RANGE ago (the beginning of that day) to the end of the present day.
export const getInitialStartDate = (defaultRange) => {
    const fromDate = new Date();
    fromDate.setTime(fromDate.getTime() - (defaultRange - 1) * DAY_IN_MS);
    const formated = formatStartDate(fromDate);
    return getUTCDateString(formated);
};

export const getInitialEndDate = () => {
    const toDate = formatEndDate(new Date());
    toDate.setTime(toDate.getTime());
    return getUTCDateString(toDate);
};
