
export const hasVerticalScrollbar = (element) => {
    // Check overflow style property on body for fauxscrollbars
    let overflowStyle;

    if (typeof element.currentStyle !== 'undefined') {
        overflowStyle = element.currentStyle.overflow;
    }

    overflowStyle = overflowStyle || window.getComputedStyle(element, '').overflow;

    // Also need to check the Y axis overflow
    let overflowYStyle;

    if (typeof element.currentStyle !== 'undefined') {
        overflowYStyle = element.currentStyle.overflowY;
    }

    overflowYStyle = overflowYStyle || window.getComputedStyle(element, '').overflowY;

    const contentOverflows = element.scrollHeight > element.clientHeight;
    const overflowShown = /^(visible|auto)$/.test(overflowStyle) || /^(visible|auto)$/.test(overflowYStyle);
    const alwaysShowScroll = overflowStyle === 'scroll' || overflowYStyle === 'scroll';

    return contentOverflows && overflowShown || alwaysShowScroll;
};


export const scrollTo = ({ element, scrollBarPosition }) => {
    let newScrollPosition = element.scrollTop;
    if (scrollBarPosition.top) {
        newScrollPosition = element.scrollHeight - element.clientHeight;
    }
    else if (scrollBarPosition.bottom) {
        newScrollPosition = 0;
    }
    element.scrollTo({ top: newScrollPosition, behavior: 'smooth' });
};
