import { config } from './config';
import { PLACEMENTS } from './constants';

export const getConfigBySize = (size) => {
    switch (size) {
        case 'xsmall': return config.XSMALL;
        case 'small': return config.SMALL;
        case 'medium': return config.MEDIUM;
        case 'large': return config.LARGE;
        default: return config.MEDIUM;
    }
};

export const getAdditionalPlacementClass = (placement) => {
    switch (placement) {
        case PLACEMENTS.CENTERED: return 'loading-centered';
        case PLACEMENTS.STICKY: return 'loading-sticky';
        case PLACEMENTS.INITIAL: return '';
        default: return '';
    }
};
