import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import './style.scss';
import { useHistory } from 'react-router';
import { findTabWithRoute } from './helpers';
import { GeneralTabContent } from './GeneralTabContent';

const GeneralRouterTabsComponent = ({ options, variant = 'primary', children, removeSearchFilter = false }) => {
	const history = useHistory();

	const handleTabSelection = ({ target: { id } }) => {
		id && options[id]?.route && removeSearchFilter ? history.push(options[id].route) : history.push(options[id].route + history.location.search);
	};

	const activatedTabKey = useMemo(() => findTabWithRoute(options, history.location.pathname), [options, history.location.pathname]);

	const activeDetailedInformationTab = history.location.pathname.includes('detailed-information');
	return (
		<GeneralTabContent handleTabSelection={handleTabSelection} activeDetailedInformationTab={activeDetailedInformationTab} activatedTabKey={activatedTabKey} options={options} variant={variant}>
			{children}
		</GeneralTabContent>
	);
};


GeneralRouterTabsComponent.propTypes = {
	options: PropTypes.oneOfType([
		PropTypes.objectOf(PropTypes.shape({
			title: PropTypes.string,
			removeSearchFilter: PropTypes.bool,
			disabled: PropTypes.oneOf([PropTypes.bool, PropTypes.any]),
			hidden: PropTypes.oneOf([PropTypes.bool, PropTypes.any]),
			component: PropTypes.oneOf([PropTypes.node, PropTypes.any]),
		})),
		PropTypes.any]),
	variant: PropTypes.oneOf(['primary', 'secondary', 'panel']),
};

export default GeneralRouterTabsComponent;
