import React from 'react';
import PropTypes from 'prop-types';

import PdfModal from 'sharedComponents/PDFModal/PdfModal';

const ApproveOnlyModal = ({ show, closeModal, title, pdfContent, acceptButtonProps }) => {
    return (
        <PdfModal
            closable={false}
            modalClasses='broadcast-message-modal'
            acceptButtonProps={acceptButtonProps}
            {...{ show, closeModal, title, pdfContent }}
        />
    );
};

ApproveOnlyModal.propTypes = {
    show: PropTypes.bool,
    closeModal: PropTypes.func,
    approveHandler: PropTypes.func,
    title: PropTypes.string,
    pdfContent: PropTypes.string
};

export default ApproveOnlyModal;
