import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';

import { IconDropDownMo, IconWarning } from 'svgIcons/MotionPortalIcons';

import colors from 'theme/_colors.scss';
import './styles.scss';

const colourStyles = {
    control: (styles, { isDisabled, selectProps: { error } }) => {
        return {
            ...styles,
            backgroundColor: isDisabled ? colors.alto : colors.white,
            borderColor: error ? colors.red : styles.borderColor,
            '&:hover': {
                borderColor: error ? colors.red : styles['&:hover'].borderColor,
            },
        };
    },
    option: (styles, { isDisabled, isFocused, data, isSelected }) => ({
        ...styles,
        fontWeight: data.bold ? 'bold' : 'normal',
        backgroundColor: isDisabled ? null : isSelected ? colors.lightBlue : isFocused ? colors.concrete : colors.transparent,
        color: isDisabled ? colors.gallery : colors.codGray,
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && colors.transparent,
        },
    }),
    menuPortal: (styles) => ({
        ...styles,
        top: 'auto'
    })
};

const ValueContainer = ({ children, ...props }) => {
    const { getValue, hasValue, isMulti } = props;
    const selectionsLength = getValue().length;
    return (
        isMulti ? <components.ValueContainer {...props}>
            {React.Children.map(children, (child) => {
                return !hasValue || child.type === components.Input ? child : null;
            })}
            {
                hasValue && (!props.selectProps.inputValue || props.selectProps.inputValue.length === 0) ?
                    <div className='selected-number'>{`${selectionsLength} selected`}</div>
                    : null
            }
        </components.ValueContainer>
            : <components.ValueContainer {...props}>{children}</components.ValueContainer>
    );
};

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <IconDropDownMo color={colors.doveGray} />
        </components.DropdownIndicator>
    );
};

const ErrorIndicator = () => {
    return (
        <div className='error-indicator'>
            <IconWarning color={colors.red} />
        </div>
    );
};

const GeneralSelectComponent = (props) => {
    const { t: translate } = useTranslation();
    const [inputValue, setInputValue] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);

    const onInputChange = (query, action) => {
        // Prevents resetting our input after option has been selected
        if (action.action !== 'set-value') {
            setInputValue(query);
            if (props.onInputChange) {
                props.onInputChange(query, action);
            }
        }
    };

    const onChange = (selected, event) => {
        setSelectedOption(selected);
        if (props.onChange) {
            props.onChange(selected, event);
        }
    };

    const components = { ValueContainer, IndicatorSeparator: () => null, DropdownIndicator };

    return (
        <div className={`general-select-wrapper ${props.error ? 'has-error' : ''}`}>
            {props.disableOnSelect && selectedOption || props.selectedValue ?
                <div className='selected-value'>
                    {props.selectedValue || selectedOption?.title}
                </div>
                :
                <Select
                    {...props}
                    components={{ ...components, ...props.components }}
                    styles={colourStyles}
                    onInputChange={onInputChange}
                    inputValue={inputValue}
                    onChange={onChange}
                    isClearable={false}
                    noOptionsMessage={() => translate('Abb.Powertrain.Frontend.lblNoOptions')}
                    className={'general-select-container ' + (props.className ? props.className : '')}
                    classNamePrefix='general-select'
                />
            }
            {props.error && <ErrorIndicator />}
        </div>
    );
};

GeneralSelectComponent.propTypes = {
    className: PropTypes.string,
    components: PropTypes.any,
    error: PropTypes.bool,
    disableOnSelect: PropTypes.bool,
    onInputChange: PropTypes.func,
    onChange: PropTypes.func,
    selectedValue: PropTypes.string
};

export default GeneralSelectComponent;
