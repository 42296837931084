import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders a list of predefined date ranges, each of them having a corresponding day span (numberOfDaysBack).
 * The seletionHandler parameter is responsible for setting the selected interval based on the above mentioned day span.
 */
function PredefinedSelectionsWrapper({ selectionHandler, children }) {

	const handleSelection = (numberOfDaysBack, numberOfHoursBack = null) => {
		selectionHandler(numberOfDaysBack, numberOfHoursBack);
	};


	// Add a reference to this component's function to every children
	const childrenWithClickHandler = children && React.Children.map(children, child =>
		React.cloneElement(child, { handleClick: handleSelection })
	);

	return (
		<div className='calendar-fixed-ranges'>
			{childrenWithClickHandler}
		</div>
	);
}

PredefinedSelectionsWrapper.propTypes = {
	selectionHandler: PropTypes.func
};

export default PredefinedSelectionsWrapper;
