import React, { useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import './styles.scss';
import { translate } from 'helpers/translateHelper';
import { hasDataSeries } from './helpers';
import { IconPopOut } from 'svgIcons/MotionPortalIcons';
import { Switch, GeneralButton } from '..';
import { map, join } from 'lodash';
import { useDispatch } from 'react-redux';
import { setMeasurementDialogParams } from '../../PowertrainAssetDetails/DetailedInformation/OperationalParameters/SingleChartView/Trend/TrendKPIList/actions';
import { assetMenuItems, actionTypes } from '../../PowertrainAssetDetails/DetailedInformation/Header/constants';

// eslint-disable-next-line react/display-name
export const withPopOutRow = (components) => (props) => {
    return <Row id='popout-chart-row-button-container' className={`popout-chart-row justify-content-end ${props.options?.exporting?.enabled && 'popout-chart-row--condition-insights'}`}>
        {useMemo(() => map(components, (Component, idx) => <Component key={idx} {...props} />), [props])}
    </Row>;
};

// eslint-disable-next-line react/display-name
export const withRightButtonGroup = (components) => (props) => {
    return <Col md='auto' className='right-button-group'>
        {useMemo(() => map(components, (Component, idx) => <Component key={idx} {...props} />), [props])}
    </Col>;
};

// eslint-disable-next-line react/display-name
export const withLeftButtonGroup = (components) => (props) => {
    return <Col className='left-button-group'>
        {useMemo(() => map(components, (Component, idx) => <Component key={idx} {...props} />), [props])}
    </Col>;
};

export const ResetZoomButton = (props) => {
    const {
        chartOptions,
        isZoomed,
        setIsZoomed,
        resetServerZoom,
        hasKpi,
    } = props;
    return hasKpi && isZoomed && (chartOptions.enableNoDataZoom || hasDataSeries(chartOptions)) ? <GeneralButton
        className='reset-zoom-button'
        type='normal'
        text={translate('ABB.Powertrain.Frontend.btnResetZoomOperationalParameters')}
        disabled={false}
        onClick={() => { resetServerZoom && resetServerZoom(); setIsZoomed(false); }}
    /> : null;
};

export const PopOutButton = (props) => {
    const {
        chartOptions,
        showChartPopout,
        setChartPopOut,
        setZooming
    } = props;

    return !showChartPopout && hasDataSeries(chartOptions) ?
        <div className='popout-button' onClick={() => {
            setZooming(true);
            setChartPopOut(true);
        }}><IconPopOut />
        </div> : null;
};

export const DownloadAll = (props) => {
    const dispatch = useDispatch();

    const downloadMeasurementGroup = () => {
        const trends = map(props.availableTrendKPIs[0]?.data, (kpi) => kpi.timeseriesKey);
        const additionalTrends = map(props.availableTrendKPIs[1]?.data, (kpi) => kpi.timeseriesKey);
        const kpis = [...trends, ...additionalTrends];

        dispatch(setMeasurementDialogParams({
            measurementTypeIds: join(kpis, ',')
        }));
        dispatch({ type: actionTypes.SELECT_MENU_ITEM, selectedMenuItem: assetMenuItems.EXPORT_MEASUREMENTS });
    };

    const {
        chartOptions,
        showChartPopout,
    } = props;

    // HIDING THIS BUTTON BY ADDING d-none CLASS, WILL BE ENABLE IN NEXT RELEASE
    return !showChartPopout && hasDataSeries(chartOptions) ?
        <div className='download-all d-none' onClick={downloadMeasurementGroup}>
            {translate('ABB.Powertrain.Frontend.downloadMeasurementsLabel')}
        </div> : null;
};

export const LastSyncDate = (props) => {
    const {
        lastMeasurementLabel,
        labelLastSync
    } = props;
    return lastMeasurementLabel && <div className='last-sync-date'>
        <span>{labelLastSync}</span>
        <span>{lastMeasurementLabel}</span>
    </div>;
};

export const EnableUniqueScaling = (props) => {
    const {
        uniqueScaling,
        enableUniqueScaling,
        setUniqueScaling
    } = props;
    return enableUniqueScaling &&
        <div className='unique-y-scale'>
            <Switch
                onChange={() => setUniqueScaling(!uniqueScaling)}
                checked={uniqueScaling}
                disabled={false}
                className='unique-y-scale-switch'
            />
            <div>{translate('ABB.Powertrain.Frontend.uniqueScaleLabel')}</div>
        </div>;
};

export const DefaultHeader = withPopOutRow([withRightButtonGroup([ResetZoomButton, PopOutButton, DownloadAll])]);
