import React, { useEffect, useState } from 'react';

import logger from 'helpers/logger';
import { getUrlForBlob } from 'helpers/pdfHelper';
import DownloadManager from 'api/DownloadManager/DownloadManager';
import { GeneralDialog, LoadingIndicator } from '..';
import { translate } from 'helpers/translateHelper';

/**
 *  Higher order component
 *  Converts an incoming blob pdf prop into a temporary url
 * 	Wrapped component gets it as a pdfContent prop,
 */
const withBlobContentPath = (WrappedComponent) => {
    const ModalWithBlob = (props) => {

        const { broadcastedMessagePath } = props;
        const [pdfUrl, setPdfUrl] = useState(null);
        const [loadedMessage, setLoadedMessage] = useState(false);

        const getLocalBlobUrl = () => {
            DownloadManager.downloadBlob(broadcastedMessagePath, 'application/pdf')
                .then((messageBlob) => {
                    if (messageBlob) {
                        setPdfUrl(getUrlForBlob(messageBlob));
                        setLoadedMessage(true);

                    }
                })
                .catch((err) => {
                    err && logger.error(err.message);
                    setLoadedMessage(false);
                });
        };

        useEffect(() => {
            getLocalBlobUrl();

            // Revoke the reference - memory efficiency
            return () => { window.URL.revokeObjectURL(pdfUrl); };
        }, []);

        if (!broadcastedMessagePath) {
            return null;
        }

        const closeFunction = props.closable === false ? null : props.closeModal;

        return (
            pdfUrl ?
                <WrappedComponent {...props} pdfContent={pdfUrl} />
                :
                <GeneralDialog
                    {...props}
                    modalClassName='dropdown-modals modal-pdf'
                    close={closeFunction}>
                    <br />
                    {loadedMessage
                        ? <b>{translate('ABB.Powertrain.Frontend.broadcastNoNotificationLabel')}</b>
                        : <LoadingIndicator size='medium' />}
                </GeneralDialog>
        );
    };

    return ModalWithBlob;
};

export default withBlobContentPath;
