import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import './style.scss';
import { useHistory } from 'react-router';
import { getDefaultTab } from './helpers';
import { GeneralTabContent } from './GeneralTabContent';

const GeneralTabsComponent = ({ options, defaultTab, variant = 'primary' }) => {
	const history = useHistory();
	const [selectedTabKey, setSelectedTabKey] = useState(getDefaultTab(options, history.location.pathname, defaultTab));

	const handleTabSelection = ({ target: { id } }) => {
		setSelectedTabKey(id);
	};

	useEffect(() => {
		options[selectedTabKey]?.hidden && setSelectedTabKey(defaultTab);
	}, [options]);

	return (
		<GeneralTabContent handleTabSelection={handleTabSelection} activatedTabKey={selectedTabKey} options={options} variant={variant}>
			{options[selectedTabKey]?.component}
		</GeneralTabContent>
	);
};

GeneralTabsComponent.propTypes = {
	defaultTab: PropTypes.any,
	options: PropTypes.oneOfType([
		PropTypes.objectOf(PropTypes.shape({
			title: PropTypes.string,
			disabled: PropTypes.oneOf([PropTypes.bool, PropTypes.any]),
			hidden: PropTypes.oneOf([PropTypes.bool, PropTypes.any]),
			component: PropTypes.oneOf([PropTypes.node, PropTypes.any]),
		})),
		PropTypes.any]),
	variant: PropTypes.oneOf(['primary', 'secondary', 'panel']),
	controlledWithRoutes: PropTypes.bool,
};

export default GeneralTabsComponent;
