/**
 *
 * SearchBoxComponent implementation
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { IconCancel, IconSearch } from 'svgIcons/MotionPortalIcons';
import colors from 'theme/_colors.scss';
import './_style.scss';

const SearchBoxComponent = (props) => {
	return (
		<div className={`search-field ${props.disabled ? 'disabled' : ''} ${props.className}`}>
			<div className='search-field-component'>
				<div className='search-input-container'>
					<input
						onChange={(e) => {
							if (typeof props.onChange === 'function') {
								props.onChange(e.target.value);
							}
						}}
						type='text'
						className='form-control'
						placeholder={props.placeholder}
						value={props.value || ''}
						disabled={props.disabled}
					/>
				</div>
				<div className='close-button-container'
					onClick={() => {
						if (typeof props.onClear === 'function') {
							props.onClear();
						}
					}}>
					{props.onClear ?
						<IconCancel
							width='24px'
							height='24px'
							color={colors.dustyGray}
						/> : null}
				</div>
				<div>
				</div>
			</div>
			<div className='search-button-container'>
				<IconSearch />
			</div>
		</div>
	);
};

SearchBoxComponent.propTypes = {
	onChange: PropTypes.func.isRequired,
	onClear: PropTypes.func,
	placeholder: PropTypes.string.isRequired,
	value: PropTypes.string,
	disabled: PropTypes.bool
};

export default SearchBoxComponent;
