import React, { memo } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import { getConfigBySize, getAdditionalPlacementClass } from './helpers';
import { rotations, PLACEMENTS } from './constants';

import './_style.scss';
import colors from 'theme/_colors.scss';

const PowertrainLoadingIndicator = ({
	size = 'medium',
	color = colors.silver,
	text = '',
	placement = PLACEMENTS.CENTERED,
	style = {
		padding: '0px 0px'
	}
}) => {
	const config = getConfigBySize(size);
	const css = `loading ${getAdditionalPlacementClass(placement)}`;
	const commonProps = {
		className: 'ABB_CommonUX_LoadingIndicator__back',
		r: config.r,
		cx: config.cx,
		cy: config.cy,
		strokeDasharray: config.strokeDasharray,
		strokeDashoffset: config.strokeDashoffset,
	};

	const quickAnimation = `5s linear 0s infinite normal forwards running ABB_CommonUX_LoadingIndicator__radial15${config.size}`;
	const slowAnimation = `5s linear 2.5s infinite normal forwards running ABB_CommonUX_LoadingIndicator__radial15${config.size}`;

	return (
		<div className={css} style={style} role='loading-indicator'>
			<div className={config.className}>
				<div>
					<svg width={config.width} height={config.height} version='1.1' xmlns='http://www.w3.org/2000/svg'>
						<g>
							{map(rotations, (rotation, index) =>
								<circle key={index} {...commonProps} transform={rotation} style={{ stroke: color, animation: quickAnimation + (index + 1) }} />
							)}
						</g>
						<g>
							{map(rotations, (rotation, index) =>
								<circle key={index} {...commonProps} transform={rotation} style={{ stroke: color, animation: slowAnimation + (index + 1) }} />
							)}
						</g>
					</svg>
				</div>
			</div>
			<div className='loading-text' style={{ color }}>{text}</div>
		</div>
	);
};

PowertrainLoadingIndicator.propTypes = {
	size: PropTypes.string,
	color: PropTypes.any,
	text: PropTypes.string,
	placement: PropTypes.oneOf([PLACEMENTS.CENTERED, PLACEMENTS.INITIAL, PLACEMENTS.STICKY, '']),
	style: PropTypes.object
};


export default memo(PowertrainLoadingIndicator);
